import {useState, useEffect} from "react";



export function ModelPrediction(inObj) {
	const [mpType, setMpType] = useState(inObj.inType);
	console.log(mpType);
	return (
		<center>
		Model Prediction
		
		</center>
	);
}


export function StockMP (props) {
	const divStyle = {
		position:"absolute",
		left:"10px",
		top:"20%",
		width:"200px",
		borderRadius: "8px",
		backgroundColor:"#ffffff",
		marginTop: "4px",
		color:"black",
		cursor:"pointer"
	};
	
	return (
		<center>
			
				Stock Prediction <br/><br/><br/><br/>
				<img src="UnderConstruction.png" className="cls_imageFlash" alt="Home"/>
			
		</center>
	);	
}


export function CryptoMP (props) {
	return (
		<center>
			Crypto Prediction <br/><br/><br/><br/>
			<img src="UnderConstruction.png" className="cls_imageFlash" alt="Home"/>
			
		</center>
	);	
}

export function CryptoCalculator(props) {
	return (
		<center>
			<br/><br/><br/><br/><br/>
			<iframe src="https://www.algosmithcomputing.com/cryptowatch.html"></iframe>
		</center>
	);
}