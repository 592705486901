import {createContext, useContext, useState} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Outlet,
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import {HtmlBody, Footer, Home, About, Contact} from "./UserWebsite.js";
import {menuOption, services, portfolioList, featureapps, GlobalMenuID} from "./UserData.js";
import {ModelPrediction, CryptoMP, StockMP} from "./ModelPrediction.js";



function App() {
	const [webObj, setWebObj] = useState(menuOption[0]);
	//const [optionID, setOptionID] = useState(0);
	const [toggleFlag, setToggelFlag] = useState(0);
	const [featureChoice, setFeatureChoice] = useState(0);
	const iconMenu = ["menu_on.png", "menu_off.png"];
	
	
	const getGlobalMenuID = useContext(GlobalMenuID);
	
	var lObjIndex=0;
	const [tempObj, setTemp] = useState(lObjIndex);
	
	
	const theOption = menuOption.map(		
		theOption => <div onClick={()=>switchPage(theOption.Id)} className="cls_menuOptionList"><img src="arrow.png" width="20" height="20" alt=""/>  {theOption.optionDisplay}</div>
	);
	
	
	const featureSwitch = function(inVal) {
		setWebObj(inVal);
		//setOptionID(inObjIndex);
		//toggleMenu();
		//localStorage.setItem("LocalStorage", inObjIndex);
		
		setFeatureChoice(inVal);
		localStorage.setItem("LocalStorage", inVal);
	}
	
		
	const featureappList = featureapps.map(
		featureappList =><div><img src="check.png" width="25" height="25" alt=""/><Link to="#" onClick={()=> {featureSwitch(featureappList.featureID)}}>{featureappList.featureAppName}</Link></div>
	);
		
		
	const toggleMenu = function() {
		setToggelFlag(toggleFlag ^ 1);		
	}
	
	const switchPage = function(inObjIndex) {		
		setWebObj(menuOption[inObjIndex]);
		//setOptionID(inObjIndex);
		toggleMenu();
		localStorage.setItem("LocalStorage", inObjIndex);
	}
	
	
	const resetToggle = function() {
		setToggelFlag(0);
	}

	
	const FeatureApp = function () {
		
		return (			
			<div className="cls_homeService">
				<BrowserRouter>
					<h3>Feature Apps:</h3>
					<b>{featureappList}</b>
					<h3>Contact us:</h3>
					
					<Link to="#" onClick={()=> switchPage(3)}><img src="email.png" width="200" height="50"/></Link><br/><br/>
					<a href="https://api.whatsapp.com/send?phone=12039338071" title="(203) 933-8071" target="_self"><img src="whatsapp.png" width="200" height="50"/></a>
					<br/><br/>
					<Routes>
						<Route index element={<></>} />
						<Route path="stock" element={<></>} />
						<Route path="crypto" element={<>f</>} />
						<Route path="calculator" element={<></>} />
						<Route path="contact" element={<></>} />
					</Routes>
				</BrowserRouter>
					<br/>
			</div>			
		);
	}

	
	console.log("From App, menuid= " + getGlobalMenuID);
	return (
		<center>
			<div className="App">
				<div className="App-header">
					<div className="cls_leftHeader">
						<button onClick={toggleMenu}>
							<img src={iconMenu[toggleFlag]} className="cls_iconImage" />				
						</button>					
					</div>
					<div className="cls_menulistHolder">
						{(toggleFlag) ? theOption : null}
						
					</div>
					<div className="cls_centerHeader">
						<a href=""><img src="algosmith_logo.png" width="300" height="100" /></a>
					</div>
					<div className="class_rightHeader">
						<font style={{textShadow: "2px 2px #000000"}}>Algosmith <br/>Computing </font> 
					</div>
				</div>			
				<div className="App-body"  onClick={resetToggle}>
					<HtmlBody webObj={webObj} featureID={featureChoice}/>
					<FeatureApp />
					<br/>
					<br/><br/><br/>
				</div>
				
				<div className="App-footer">
					<Footer />
				</div>			
				<br/><br/><br/><br/>
			</div>
			
			<div className="cls_bottom"><a href="https://api.whatsapp.com/send?phone=12039338071" title="(203) 933-8071" target="_self"><img src="whatsapp.png" width="250" height="50"/></a></div>	
		</center>
	);
}

export default App;
