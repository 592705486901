import {createContext, useContext, useRef, useState, useEffect, React} from "react";


export const GlobalMenuID = createContext(0);


export const flashAds = [
	{adText: "Data-driven business decisions and strategies"
	}, {
			backgroundColor:"#000000",
			opacity:"0.7",
			position:"relative",
			top:"5%",
			left:"2%",
			width:"90%",
			height:"600px",
			paddingLeft:"30px",
			borderRadius: "8px",
			opacity: 0.85
		
	},
	{adText: "Do you need to improve your corporate business infrastructure?"
	}, {
			backgroundColor:"#000000",
			opacity:"0.7",
			position:"relative",
			top:"5%",
			left:"2%",
			width:"90%",
			height:"600px",
			paddingLeft:"30px",
			borderRadius: "8px",
			opacity: 0.85
		
	},
	{adText: "Are you wondering how your company performs?"
	}, {
			backgroundColor:"#000000",
			opacity:"0.7",
			position:"relative",
			top:"5%",
			left:"2%",
			width:"90%",
			height:"600px",
			paddingLeft:"30px",
			borderRadius: "8px",
			opacity: 0.85
		
	}
];

export const menuOption = [
	{Id:0,
	 path: "/",
	 pageName: "home", 
	 optionDisplay: "Home"
	},
	{Id:1,
	 path: "/about",
	 pageName: "about", 
	 optionDisplay: "About"
	},
	{Id:2,
	 path: "/service",
	 pageName: "service", 
	 optionDisplay: "Services"
	},	
	{Id:3,
	 path: "/contact",
	 pageName: "contact", 
	 optionDisplay: "Contact"
	}
];


export const menuIcon = [
	{Id: 0,
	 iconName: "menu_on.png",
	 width: 40,
	 height: 40
	}, {
	 Id: 1,
	 iconName: "menu_off.png",
	 width: 40,
	 height: 40
	}
];


export const services = [
	{serviceName: "Custom Software Development (Web-based and non Web-based)",
	 serviceDetail: "We build and maintain all types of web applications either front-end, back-end, or full stack.",
	 imageName: "softwaredev.png"
	},
	
	{serviceName: "AI Projects and Data Engineering",
	 serviceDetail: "We build and maintain all types of web applications either front-end, back-end, or full stack.",
	 imageName: "softwaredev.png"
	},	
	{serviceName: "Database Projects",
	 serviceDetail: "We do all types of Database projects",
	 imageName: "softwaredev.png"
	},
	{serviceName: "Computer Network and Cyber Security",
	 serviceDetail: "We do all types of Database projects",
	 imageName: "softwaredev.png"
	},
	{serviceName: "Internet Marketing and SEO",
	 serviceDetail: "We do all types of Database projects",
	 imageName: "softwaredev.png"
	},
	{serviceName: "Disaster Recovery",
	 serviceDetail: "We do all types of Database projects",
	 imageName: "softwaredev.png"
	},
	{serviceName: "Information Technology and Systems Services",
	 serviceDetail: "",
	 imageName: "softwaredev.png"
	}
];


export const featureapps = [
	{featureAppName: "Stock Prediction",
	 featureID: 10,
	 path: "stock",
	},
	{featureAppName: "Crypto Prediction",
	 featureID: 11,
	 path: "crypto"
	},
	{featureAppName: "Crypto Calculator",
	 featureID: 12,
	 path: "calculator"
	}
];


export const portfolioList = [
	{imageName: "mgear.png",
	 altDisplay: "This is what we have done"
	},
	{imageName: "gymboree.png",
	 altDisplay: "This is what we have done"
	},
	{imageName: "miamidolphin.png",
	 altDisplay: "This is what we have done"
	}
];