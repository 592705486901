import { useFormStatus } from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Outlet,
} from "react-router-dom";
import logo from './logo.svg';
import {createContext, useContext, useRef, useState, useEffect, React} from "react";
import {menuOption, services, portfolioList, featureapps, GlobalMenuID} from "./UserData.js";
import {ModelPrediction, CryptoMP, StockMP, CryptoCalculator} from "./ModelPrediction.js";



const serviceList = services.map(		
	serviceList => <><img src="check.png" width="25" height="25" alt=""/> {serviceList.serviceName}<br/></>
); 


export const MyContext = createContext();


export function Home(props) {
	return (
		<center>			
			<img src="home.jpg" className="cls_imageFlash" alt="Home"/>	
		</center>
	);
}


export function About() {
	
	
  return (
	<center>
    <div className="cls_about">
	
		<h2>About Us</h2>
		<p>Welcome to Algosmith Computing, your trusted partner in IT solutions.</p>

		<p>We've been providing custom software development and consulting services to businesses of all sizes since Year 2000. Our team has a proven track record of excellence, having involved in major projects for renowned companies such as:</p>
		
		<li> Proshop of MiamiDolphins.com</li>
		<li> Proshop of Si.com (sports illustrated)</li>
		<li> Proshop of Wwe.com (World Wrestling Federation)</li>
		<li> LillianVernon.com</li>
		<li> Gymboree.com</li>
		<li> Mgear.com</li>
		<li> Monsanto.com</li>
		<li> among others. </li>
		
		<p>For each project, we've brought our expertise, dedication, and innovative approach to the table, ensuring that our clients receive top-tier service and results. Our extensive experience in handling high-profile assignments speaks to our capability and commitment to delivering solutions that meet and exceed expectations.</p>
		
		<p>With years of industry experience and a team of seasoned professionals, we are committed to helping our clients leverage technology to drive growth, streamline operations, and achieve their business objectives.</p>

		<p><b>Our Mission</b></p>

		<p>Our mission is to deliver innovative and tailored IT solutions that empower our clients to thrive in today's dynamic business environment. We strive to understand our clients' unique challenges and goals, and we work collaboratively to develop customized strategies and solutions that meet their specific needs.</p>

		<p><b>What Sets Us Apart</b></p>
		<blockquote>
			<p> <b>Expertise:</b> Our team consists of highly skilled and experienced IT professionals who are passionate about delivering exceptional results.</p>
			<p> <b>Client-Centric Approach:</b> We prioritize building long-term relationships with our clients and are dedicated to providing personalized service and support.</p>
			<p> <b>Innovation:</b> We stay abreast of the latest technological advancements and trends to ensure that our clients benefit from cutting-edge solutions.</p>
			<p> <b>Value:</b> We are committed to delivering value-driven solutions that help our clients achieve tangible business outcomes and maximize their return on investment.</p>
		</blockquote>
		<br/><br/><br/>
	</div>
	</center>
  );
}


export function Service() {
	const  cls_innerService = {
		backgroundColor:"#000000",
		opacity:"0.7",
		position:"relative",
		top:"5%",
		left:"2%",
		width:"90%",
		height:"600px",
		paddingLeft:"30px",
		borderRadius: "8px"		
	};
	
	function animateService() {
		
	}
	
	return (
		<center><br/><br/><br/><br/><br/>
		<div className="cls_serviceList" style={{backgroundImage: "url(IMG-20240511-WA0017.jpg)"}}>
			<div style={cls_innerService}>
				<br/>
				<h2><nobr>We provide services:</nobr></h2>
				<br/>
					{serviceList}
				<br/>
				<br/><br/>
			</div>
		</div>
		</center>
	);	
}


export function Portfolio() {
	const [count, setCount] = useState(0);
	let maxCounter = portfolioList.length-1;
	
	function imageFlipper() {		
		setCount((count < maxCounter) ? (count+1) : 0);
	};	
		
	function animationFunct() {
		setTimeout(imageFlipper, 5000);		
	}
	
	useEffect(animationFunct);
	//useEffect(setTimeout(imageFlipper, 5000));
	
	return (
		<center>
			<div className="cls_portfolio"><br/>
			Some projects we have involved<br/><br/>
				<img src={portfolioList[count].imageName} alt={portfolioList[count].altDisplay}  className="cls_imagePortfolio"/>							
			</div>
		</center>
	);	
}


export function Testimonial(props) {

	return (
		<center>
		<iframe src="https://www.chamberofcommerce.com/business-directory/michigan/westland/towing-service/2016144668-charlie-towing-llc"></iframe>
		</center>
	);
}


export function Contact(props) {
	const [submitToggle, toggleSwith] = useState(props.isSubmit);
	
	//console.log("You reach contact " + submitToggle);
	const processForm = async function(event) {
		// Prevent the browser from reloading the page
		event.preventDefault();

		// Read the form data
		const formTarget = event.target;
		const formData = new FormData(formTarget);
		console.log(formData);
		const formJson = Object.fromEntries(formData.entries());
		console.log(formJson);
		/*
		let fetchData = {
				  method: "post",
				  headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				  },
				  body: JSON.stringify(formJson)
		};
		*/
		
		//let responseObj = await fetch("http://localhost/MyStock/ProcessEmail.php", fetchData);
		let stringifiedFormJSon = JSON.stringify(formJson)
		let responseObj = await fetch("ProcessEmail.php?package=" + stringifiedFormJSon);
		let responseText = await responseObj.text();

		if (responseObj.ok) {
			// Handle success response
			console.log(responseText);			
			toggleSwith(1);
		} else {
			// Handle error response
			console.log("Fail");
		}
	}
	
	
	if (submitToggle === 0) {
		return (
		 
			<div className="cls_form" 
						style={{backgroundImage: "url(IMG-20240511-WA0029.jpg)", 
								backgroundPosition: "center",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat"
								}}>
				<br/><br/><br/><br/>
				<form method="post" onSubmit={processForm}>				
					<div className="cls_formRow">
						<div id="id_name" className="cls_label">
							Name* : 
						</div>
						<div className="cls_field">
							<input type="text" id="id_fullName" name="fullName" required />
						</div>
					</div>
					<div className="cls_formRow">
						<div id="id_email" className="cls_label">
							Email* : 
						</div>
						<div className="cls_field">
							<input type="text" id="id_email" name="email" required />
						</div>
					</div>
					<div className="cls_formRow">
						<div id="id_email" className="cls_label">
							Phone* : 
						</div>
						<div className="cls_field">
							<input type="text" id="id_phone" name="phone" required />
						</div>
					</div>
					<div className="cls_formRow">
						<div id="id_message" className="cls_label">
							Message* : 
						</div>
						<div className="cls_field">
							<textarea type="text" id="id_message" name="message" required ></textarea>
						</div>
					</div>
					<div className="cls_formRow">
						<div className="cls_label">
						</div>
						<div className="cls_field">
							<button type="submit" className="cls_submit">
								SUBMIT
							</button>
							
						</div>
					</div>			
				</form><br/><br/><br/>
			</div>
		
		);
	} else {
		return (
			<div className="cls_form" >
				<center><br/><br/><br/><br/>
				<b>We will get back to you as soon as possible!!</b>
				<br/><br/><br/>
				<img src="thankyou.gif" width="100%" height="100%" alt="Thank you!!"/>
				<br/><br/><br/><br/><br/><br/><br/><br/><br/></center>
			</div>
		);
	}
}



export function Footer(props) {
	const dateObj  = new Date();
	let lYear = dateObj.getFullYear();
	
	return (		
		<center>
			<div style={{textAlign:"center"}}><img src={logo} className="cls_builtby" alt="logo" /> built with ReactJS</div>				
			© 2000-{lYear} Algosmith Computing. All Rights Reserved.
		</center>		
	);
}


export function HtmlBody (props) {
		
	if (props.webObj.pageName === "about" ) {
		return (
			<About/>
		);
	} else if (props.webObj.pageName === "service" ) {
		return (
			<Service/>
		);
	} else if (props.webObj.pageName === "portfolio" ) {
		return (
			<Portfolio />
		);
	} else if (props.webObj.pageName === "testimonials" ) {
		return (
			<Testimonial />
		);
	} else if (props.webObj.pageName === "contact" ) {
		return (
			<Contact isSubmit={0} />
		);
	} else if (props.webObj.pageName === "home" ){
		return ( 
			<Home landingBase={0}/>
		);
	} else {		
		if (props.featureID === 10) {
			return (
				<StockMP inType={0}/>
			);
		} else if (props.featureID === 11) {
			return (
				<CryptoMP inType={0}/>
			);
		} else {
			return (
				<CryptoCalculator />
			);
		}	
	}
}